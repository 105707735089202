import React, { Fragment, Component } from "react";
import i18n, { t } from "i18next";
import { errorHandler, //numberWithSpaces, shortQuantity, 
    withRouter } from "../../utils/GlobalFunctions";
import InputMask from "react-input-mask";
//import NumericInput from "../../utils/NumericInput"
import { Form, Input, Button,  Select, Table, Modal, Checkbox } from "antd";
import { getBaseURL } from "../../utils/axiosRequest";
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";
//import { number } from "prop-types";
//import MobileInvoiceForm from "./MobileInvoiceForm";


export class FillForm extends Component{
    constructor(props) {
        super(props);
    
        this.state = {
          form_id: null,  
          elemService: [],
          grandTotal: 0,
          tinValue: "",
          telValue: "",
          data: null,
          requestFailed: false,
          userforms: null,
          isLoaded: false,
          showInvoice: false,
        };
    }

    componentDidMount() {
        this.setData();   
    }

    setData() {
        const form = this.props.location.state.formItem;
        let newArr = [];
        let sum = 0;
        this.setState({form_id: form.form_id});
        form.services.map((elem, id)=>{
            newArr.push({
                'id': elem.service_id, 
                'qty': elem.unit_qty, 
                'dsc': null, 
                'price': (elem.amount/100).toFixed(2), 
                'total': (elem.unit_qty * elem.amount/100).toFixed(2)
            });    
                sum += parseFloat(newArr[id].total);
            
            }
        );

        this.setState({elemService: newArr, grandTotal: sum, isLoaded: true});
    }
    
    componentDidUpdate() {}

    onFinish = (fieldsValue) => {
        let newArr = [];
        newArr = this.state.elemService.filter((elem, _) => elem.qty !== 0);
        let phonenumber = fieldsValue['phonenumber'];
        let val = fieldsValue;
        val['Плательщик'] = fieldsValue['customer']; val['РМА'] = fieldsValue['tin'];
        delete val['customer']; delete val['tin']; delete val['phonenumber'];
        
        fetch(`${getBaseURL()}invoices/new`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
            body: JSON.stringify({
                'form_id': this.state.form_id,
                'bill_to': val,
                'customer_phonenumber': phonenumber, 
                'services': newArr
            })
          }).then(response =>{ return errorHandler(response, 'FillForm.jsx - onFinish', this.props.navigate) }
          ).then((responseJson) => {
            this.props.form.resetFields();
            Modal.info({  
                title: 'Ҳисобномаи нав сохта шуд!',
                content: 
                <div>
                    <div className="font-bold">{`№ ${responseJson.invoice_number}`}</div>
                    <div className="text-justify">{`${responseJson.subject[i18n.language]}`}</div>
                    <div className="font-bold">{`${(responseJson.amount_due/100).toFixed(2)} сомони`}</div>
                </div>
            });
            
            this.props.navigate('/payments/forms')
        });
        
    }

    handleFile = (e) => {

    };

    numHandler = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((reg.test(inputValue) || inputValue === '' || inputValue === '-') && inputValue.length < 10) {
            this.setState({telValue: inputValue});
        }
    };

    tinHandler = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((reg.test(inputValue) || inputValue === '' || inputValue === '-') && inputValue.length < 10) {
            this.setState({tinValue: inputValue});     
        }
    };

render() {
    const { telValue, tinValue, grandTotal, elemService } = this.state;
    const {formItem} = this.props.location.state;
    const {translation} = this.props;
    
    const form_columns = [
        {
            title: translation("DESCRIPTION"),
            width: "54%",

            dataIndex: 'name',
            key: 'name',
            render: (name, row, rowIndex) => {
                return ( row.selectable ?
                    <>
                    <Checkbox value={row.key} 
                        onChange={(e)=>{
                            console.log('Checkbox changed', e.target.checked)
                            let newArr = [...elemService];
                            let sum = 0;
                            if(e.target.checked) {
                                newArr[rowIndex].qty = 1;
                            }
                            else {
                                newArr[rowIndex].qty = 0;
                            }
                            newArr[rowIndex].total = ((parseFloat(newArr[rowIndex].qty) || 0) * parseFloat(newArr[rowIndex].price)).toFixed(2);
                            newArr.forEach((num) => {sum += parseFloat(num.total);});
                            this.setState({elemService: newArr, grandTotal: sum});
                        }
                    }
                    >{name}
                    </Checkbox>
                    </>  
                    : <div className="w-full">{name}</div>);
            },
        },
        {
            title: translation("MEASUREMENT"),
            width: "8%",
            align: 'center',
            dataIndex: 'unit_name',
            key: 'unit_name',
            render: (unit_name, row) => {
                return (<div className="w-full flex justify-center">{unit_name}</div>
                );
            },
        },
        {
            title: translation("QTY"),
            width: "8%",
            dataIndex: 'unit_qty', 
            key: 'unit_qty',        
            render: (unit_qty, row, rowIndex) => {
                const q_ty = elemService[rowIndex]?.qty
                return (row.discount !== null ?
                    <Select className="text-center"
                        id={"qty_" + row.service_id}
                        defaultValue="1"
                        style={isMobile ? { fontSize: 12, width: 80 } : {fontSize: 12, width: 140}}
                        onChange={(value, option)=>{
                            console.log('Select changed', value)
                            let newArr = [...elemService];
                            let sum = 0;
                            newArr[rowIndex].qty = 1 - value;
                            if (value !== 0) {newArr[rowIndex].dsc = option.label;} else {newArr[rowIndex].dsc = null};
                            newArr[rowIndex].total = ((parseFloat(newArr[rowIndex].qty) || 0) * parseFloat(newArr[rowIndex].price)).toFixed(2);
                            newArr.forEach((num) => {sum += parseFloat(num.total);});
                            this.setState({elemService: newArr, grandTotal: sum});
                        }}
                        options={row.discount}
                    />
                    :row.unit_qty ===0 ?
                    <input id={"qty_" + rowIndex} className="text-center bg-white border px-2 py-1 rounded-[4px] w-full mx-auto" type="number" name="price"
                        defaultValue={q_ty}
                        onChange={(e)=>{
                            console.log('Input changed', e.target.value)
                            let newArr = [...elemService];
                            let sum = 0;
                            newArr[rowIndex].qty = e.target.value;
                            newArr[rowIndex].total = ((parseFloat(newArr[rowIndex].qty) || 0) * parseFloat(newArr[rowIndex].price)).toFixed(2);
                            newArr.forEach((num) => {sum += parseFloat(num.total);});
                            //console.log(newArr);
                            this.setState({elemService: newArr, grandTotal: sum});
                        }}
                    />
                    :<div className="w-full flex justify-center">{q_ty}</div>
                );
            },
        },
        {
            title: translation("PRICE"),
            width: "15%",
            align: 'right',
            dataIndex: 'amount',
            key: 'price',
            render: (amount, row, rowIndex) => {
                const price = elemService[rowIndex]?.price
                return (
                     
                    row.amount === 0 ?
                    <input id={"price_" + rowIndex} className="text-right bg-white border px-2 py-1 rounded-[4px] w-full mx-auto" type="number" name="price"
                        defaultValue={price}
                        onChange={(e)=>{
                            let newArr = [...elemService];
                            let sum = 0;
                            newArr[rowIndex].price = e.target.value;
                            newArr[rowIndex].total = ((parseFloat(newArr[rowIndex].price) || 0) * parseFloat(newArr[rowIndex].qty));
                            newArr.forEach((num) => {sum += parseFloat(num.total);});
                            //console.log(newArr);
                            this.setState({elemService: newArr, grandTotal: sum});
                        }}
                    />
                    : <div className="w-full flex justify-end">{elemService[rowIndex]?.price}</div>
                );
            },
        },
        {
            title: translation("SUM"),
            width: "15%",
            align: 'right',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount, row, rowIndex) => {
                return ( <div className="w-full flex justify-end">{ elemService[rowIndex]?.total }</div>);
            },
        },
    ]
    
return (
    <div className="w-full">{!formItem && !this.state.isLoaded 
        ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
        : <div className="block">
            <Form id="myform" className="w-full" form={this.props.form} onFinish={this.onFinish}>
            <div className="sticky top-0 z-[99]">
                <div className="bg-[#18526B] flex justify-center p-2 text-white">
                    <div className="ml-4 flex text-white items-center sm:text-m md:text-l lg:text-l xl:text-2xl m-0 border-r-2 pr-3">
                        {formItem.form_id < 10 ? `0${formItem.form_id}` : formItem.form_id}
                    </div>
                    <div className="w-full pl-[2rem] flex items-center text-white sm:text-base md:text-lg m-0">
                        {formItem.form_name.tj}
                    </div>  
                </div>
                <div className="w-full mb-[2rem]">
                    <div className="sm:block md:flex bg-[#fff] rounded-b-[8px] py-4 shadow-lg">
                        <div className="flex flex-col md:w-[30%] space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                            <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                            {translation("PAYER")}
                            </p>
                            <Form.Item name='customer' className="m-0">
                                <Input
                                    className="bg-white border px-3 py-2 rounded-lg"
                                    type="text"
                                    name="customer"
                                    required
                                />
                            </Form.Item>
                        </div>
                        <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                            <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                            {translation("PHONE_NUMBER")}
                            </p>
                            <Form.Item name='phonenumber' className="m-0">
                            <Input
                                className="bg-white border px-3 py-2 rounded-lg"
                                type="text"
                                name="tel"
                                required
                                value={telValue}
                                onChange={this.numHandler}
                            />
                            </Form.Item>
                        </div>
                        <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                            <p className="m-0 text-xs text-[#6A6A6A] pl-3">РМА</p>
                            <Form.Item name='tin' className="m-0">
                            <Input
                                className="bg-white border px-3 py-2 rounded-lg"
                                type="text"
                                name="tin"
                                required
                                value={tinValue}
                                onChange={this.tinHandler}
                            />
                            </Form.Item>
                        </div>
                        {formItem?.fill_form.map((elem) => {
                            if (elem.type === "number") {
                            return (
                                <Fragment key={elem.id}>
                                <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                                    <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                                    
                                    </p>
                                    <Form.Item name={elem.input} className="m-0">
                                        <InputMask
                                        className="bg-white border px-3 py-2 rounded-lg"
                                        mask={elem?.mask}
                                        />
                                    </Form.Item>
                                </div>
                                </Fragment>
                            );
                            }
                            if (elem.type === "file") {
                            return (<>
                                {/*<Fragment key={elem.id}>
                                <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                                    <p className="text-xs m-0 pl-3 text-[#6A6A6A]">
                                    {elem.input}
                                    </p>
                                    <label
                                    className="flex hover:cursor-pointer border-2 py-2 px-4 rounded-full"
                                    htmlFor="fileUploader"
                                    >
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#006D7D"
                                        className="w-6 h-6"
                                    >
                                        <path
                                        fillRule="evenodd"
                                        d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                                        clipRule="evenodd"
                                        />
                                    </svg>
                                    <Form.Item name={elem.input} className="m-0">
                                    <input
                                        type="file"
                                        id="fileUploader"
                                        style={{ display: "none" }}
                                        onChange={this.handleFile}
                                        name={elem.input}
                                    />
                                    </Form.Item>
                                    <div className="pl-4 text-[#006D7D]">
                                        {translation("CHOOSE_FILE")}
                                    </div>
                                    </label>
                                </div>
                            </Fragment>*/}</>
                            );
                            }

                            return (
                            <Fragment key={elem.id}>
                                <div className="flex flex-col space-y-2 sm:px-4 md:px-5 lg:px-5 xl:px-8">
                                <p className="m-0 text-xs text-[#6A6A6A] pl-3">
                                    {elem.input}
                                </p>
                                <Form.Item name={elem.input} className="m-0">
                                <Input
                                    className="bg-white border px-3 py-2 rounded-lg"
                                    type={elem.type}
                                    name={elem.input}
                                />
                                 </Form.Item>
                                </div>
                            </Fragment>
                            );
                        })}
                        
                    </div>
                    
                </div>
            </div>
            <div className="block w-full">
                <div className="w-[100%] md:px-[2rem]">
                <Table
                    footer={()=>{
                        return(
                            <div className="w-full flex justify-end">
                            <Button htmlType="submit" className="text-white bg-emerald-600 hover:bg-white">
                                <div className="flex items-center">
                                <div className="flex mr-4 items-center">
                                    <CheckCircleOutlined color="#fff" />
                                </div>
                                <div>{t("CREATE_INVOICE")}</div>
                                </div>
                            </Button>
                            </div>
                        )}
                    }
                    className="shadow-lg"
                    columns={isMobile ? form_columns.filter((elem, index) => index !== 1 ): form_columns}
                    dataSource={formItem.services}
                    pagination={false}
                    size="small"
                    summary={()=>{
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell className="text-right" index={1} colSpan={isMobile  ? 3 : 4}>
                                    <div className="text-red-500 tracking-wide font-bold uppercase">{`${translation("TOTAL_INVOICE_AMOUNTS")} (${translation("TJS")}):`}</div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="text-right" index={2} colSpan={1}>
                                    <div className="text-red-500 tracking-wide font-bold">{grandTotal.toFixed(2)}</div>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                        )
                    }}
                    
                ></Table>
                </div>  
            </div>
            </Form>
        </div>
    }
    </div>
)}
}

export default withRouter(FillForm);